<template>
  <div>
    <!-- breadcrumb面包屑导航区域 -->
    <!-- 首页/活动管理/活动列表/活动详情 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form :model="queryinfo" ref="queryinfoRef">
          <el-col :span="6.5">
            <el-form-item prop="queryname">
              <div style="display: inline-block" class="divSpan">名称：</div>
              <div style="display: inline-block">
                <el-input
                  placeholder="请输入搜索的名称"
                  v-model.trim="queryinfo.queryname"
                  clearable
                  @clear="getRoleList"
                >
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-form>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
        </el-col>
      </el-row>

      <el-row :gutter="30">
        <el-col :span="3">
          <el-button
            round
            type="primary"
            icon="el-icon-circle-plus"
            @click="addDialogVisible = true"
            >添加角色</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="RoleList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="id"
            label="ID"
            sortable
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            sortable
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="note"
            label="说明"
            min-width="200px"
          ></el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            min-width="80px"
          ></el-table-column>

          <el-table-column label="权限" min-width="250px">
            <template slot-scope="scope">
              <!-- 查看按钮 -->
              <el-button
                type="primary"
                size="mini"
                @click="viewprivilegeEditDialog(scope.row)"
              >
                查看</el-button
              >
              <!-- 分配角色权限 按钮 -->

              <el-button
                type="primary"
                size="mini"
                @click="addprivilegeDialog(scope.row)"
                >分配</el-button
              >
              <!-- 移除角色中权限 按钮-->
              <el-button
                type="primary"
                size="mini"
                @click="removeprivilegeDialog(scope.row)"
                >移除</el-button
              >
            </template>
          </el-table-column>

          <el-table-column label="用户" min-width="250px">
            <template slot-scope="scope">
              <!-- 查看按钮 -->
              <el-button
                type="primary"
                size="mini"
                @click="viewuserEditDialog(scope.row)"
                >查看</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="adduserDialog(scope.row)"
                >分配</el-button
              >

              <el-button
                type="primary"
                size="mini"
                @click="removeuserDialog(scope.row)"
                >移除</el-button
              >
            </template>
          </el-table-column>

          <!-- operation 渲染 -->
          <el-table-column label="操作" min-width="150px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="showEditDialog(scope.row)"
                >编辑</el-button
              >
              <!-- 删除按钮 -->
              <el-button
                type="danger"
                size="mini"
                @click="removeRoleById(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="添加角色信息"
      :visible.sync="addDialogVisible"
      width="30%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域  表单-->
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <el-form-item label="名称：" prop="name">
          <el-input v-model.trim="addForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="说明：" prop="note">
          <el-input v-model.trim="addForm.note" clearable></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addROle">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="编辑角色信息"
      :visible.sync="editDialogVisible"
      width="30%"
      @close="editDialogClosed"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRef"
        label-width="100px"
      >
        <el-form-item label="名称：" prop="name">
          <el-input v-model.trim="editForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="说明：" prop="note">
          <el-input v-model.trim="editForm.note" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editRoleInfo">确定</el-button>
      </span>
    </el-dialog>

    <!-- 分配角色权限 info-->
    <el-dialog
      :close-on-click-modal="false"
      :title="PrivilegeTitle"
      :visible.sync="ALLPrivilegeDialogVisible"
      width="60%"
    >
      <el-row :gutter="30">
        <!-- 搜索与添加区域 clearable 清空-->
        <el-col :span="8">
          <div style="display: inline-block">标识：</div>
          <div style="display: inline-block">
            <el-input
              placeholder="请输入搜索的标识"
              v-model="queryAssignInfo.queryname"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="2.5">
          <el-button type="primary" icon="el-icon-search" @click="seachChange1"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>

      <i class="texts">可用的权限列表</i>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        style="float: right"
        @click="SelectedPrivlege"
        >添加</el-button
      >
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table :data="PrivilegeAvailList" border stripe>
          <el-table-column width="50px">
            <template slot-scope="scope">
              <div v-if="typeof scope.row.PrivilegeExisted == 'undefined'">
                <el-checkbox
                  @change="CheckPrivilege(scope.row)"
                  v-model="scope.row.checkchange"
                ></el-checkbox>
              </div>
              <div v-else>
                <el-checkbox v-model="checked" disabled></el-checkbox>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID" sortable></el-table-column>
          <el-table-column prop="note" label="说明"></el-table-column>
          <el-table-column prop="tag" label="标识" sortable></el-table-column>
          <el-table-column prop="type" label="类型" sortable></el-table-column>
          <el-table-column
            prop="resourceId"
            label="资源ID"
            sortable
          ></el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleAssignSizeChange"
        @current-change="handleAssignCurrentChange"
        :current-page="queryAssignInfo.pageNum"
        :page-size="queryAssignInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="Assigntotal"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ALLPrivilegeDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="AssignPrivilege">确定</el-button>
      </span>
      <el-divider></el-divider>

      <i class="texts">已添加的权限列表</i>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table :data="privlegeaddedList" border stripe max-height="350">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="note" label="说明"></el-table-column>
          <el-table-column prop="tag" label="标识"></el-table-column>
          <el-table-column prop="type" label="类型"></el-table-column>
          <el-table-column prop="resourceId" label="资源ID"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removePrivlegeById(scope.row)"
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="查看权限"
      :visible.sync="viewPrivilegeDialogVisible"
      width="50%"
    >
      <el-row :gutter="30" style="display: flex">
        <el-table :data="PrivilegeviewList" border stripe max-height="350">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID" sortable></el-table-column>
          <el-table-column prop="note" label="说明"></el-table-column>
          <el-table-column prop="tag" label="标识" sortable></el-table-column>
          <el-table-column prop="type" label="类型" sortable></el-table-column>
          <el-table-column
            prop="resourceId"
            label="资源ID"
            sortable
          ></el-table-column>
        </el-table>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewPrivilegeDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!-- 移除角色权限 按钮-->
    <el-dialog
      :close-on-click-modal="false"
      :title="RemovePrivilegeTitle"
      :visible.sync="removePrivilegeDialogVisible"
      width="60%"
    >
      <i class="texts">已存在的权限列表</i>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        style="float: right"
        @click="RemovedPrivilege"
        >添加</el-button
      >
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="PrivilegemoveList"
          border
          stripe
          @selection-change="handleRemovedChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID" sortable></el-table-column>
          <el-table-column prop="note" label="说明"></el-table-column>
          <el-table-column prop="tag" label="标识" sortable></el-table-column>
          <el-table-column prop="type" label="类型" sortable></el-table-column>
          <el-table-column
            prop="resourceId"
            label="资源ID"
            sortable
          ></el-table-column>
        </el-table>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="removePrivilegeDialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="removePrivilege">移除</el-button>
      </span>
      <el-divider></el-divider>

      <i class="texts">选择要移除的权限列表</i>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="confirmRemovedPrivilege"
          border
          stripe
          max-height="350"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="note" label="说明"></el-table-column>
          <el-table-column prop="tag" label="标识"></el-table-column>
          <el-table-column prop="type" label="类型"></el-table-column>
          <el-table-column prop="resourceId" label="资源ID"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="NotremovePrivilegeById(scope.row)"
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <!--查看角色用户列表-->
    <el-dialog
      :close-on-click-modal="false"
      title="查看角色用户"
      :visible.sync="viewUserDialogVisible"
      width="60%"
    >
      <el-row :gutter="30" style="display: flex">
        <el-table :data="UserviewList" border stripe max-height="350">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID" sortable></el-table-column>
          <el-table-column prop="name" label="姓名" sortable></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            sortable
          ></el-table-column>
          <el-table-column prop="email" label="邮箱" sortable></el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            sortable
          ></el-table-column>
        </el-table>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="viewUserDialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!-- 分配角色用户 info-->
    <el-dialog
      :close-on-click-modal="false"
      :title="RoleUserTitle"
      :visible.sync="ALLRoleUserDialogVisible"
      width="60%"
    >
      <el-row :gutter="30">
        <!-- 搜索与添加区域 clearable 清空-->
        <el-col :span="8">
          <div style="display: inline-block">姓名：</div>
          <div style="display: inline-block">
            <ELselect
              v-model="queryRoleUserInfo.queryname"
              :options="Names"
              :optionKey="Nameskeys"
              Splaceholder="请选择搜索的姓名"
            ></ELselect>
          </div>
        </el-col>
        <el-col :span="2.5">
          <el-button type="primary" icon="el-icon-search" @click="seachChange2"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>

      <i class="texts">可用的角色用户列表</i>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        style="float: right"
        @click="SelectedRoleUser"
        >添加</el-button
      >
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table :data="RoleUserAvailList" border stripe>
          <el-table-column width="50px">
            <template slot-scope="scope">
              <div v-if="typeof scope.row.RoleUserExisted == 'undefined'">
                <el-checkbox
                  @change="CheckRoleUser(scope.row)"
                  v-model="scope.row.checkchange"
                ></el-checkbox>
              </div>
              <div v-else>
                <el-checkbox v-model="checked1" disabled></el-checkbox>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID" sortable></el-table-column>
          <el-table-column prop="name" label="姓名" sortable></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            sortable
          ></el-table-column>
          <el-table-column prop="email" label="邮箱" sortable></el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            sortable
          ></el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleRoleUserSizeChange"
        @current-change="handleRoleUserCurrentChange"
        :current-page="queryRoleUserInfo.pageNum"
        :page-size="queryRoleUserInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="RoleUsertotal"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ALLRoleUserDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="RoleUserPrivilege">确定</el-button>
      </span>
      <el-divider></el-divider>

      <i class="texts">已添加的角色用户列表</i>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table :data="RoleUseraddedList" border stripe max-height="350">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="email" label="邮箱"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeRoleUserById(scope.row)"
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <!-- 移除角色用户 按钮-->
    <el-dialog
      :close-on-click-modal="false"
      :title="RemoveRoleUserTitle"
      :visible.sync="removeRoleUserDialogVisible"
      width="60%"
    >
      <i class="texts">已存在的角色用户列表</i>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        style="float: right"
        @click="RemovedRoleUser"
        >添加</el-button
      >
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="RoleUsermoveList"
          border
          stripe
          @selection-change="handleRemovedRoleUserChange"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID" sortable></el-table-column>
          <el-table-column prop="name" label="姓名" sortable></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            sortable
          ></el-table-column>
          <el-table-column prop="email" label="邮箱" sortable></el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            sortable
          ></el-table-column>
        </el-table>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="removeRoleUserDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="removeRoleUser">移除</el-button>
      </span>
      <el-divider></el-divider>

      <i class="texts">选择要移除的角色用户列表</i>
      <el-divider></el-divider>
      <el-row :gutter="30" style="display: flex">
        <el-table :data="confirmRemovedRoleUser" border stripe max-height="350">
          <el-table-column type="index"></el-table-column>
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="email" label="邮箱"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="NotremoveRoleUserById(scope.row)"
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { getRoleUsers, batchInsertUser, batchDeleteUser, RolefindPage, RolefindNamePage, addRole, deleteRole, updateRole, getRoleById, countByName, getRolePermissions, batchInsertPermission, batchDeletePermission } from '@/api/systemanager/role'
import { getPrivilegeById, PrivilegefindPage, PrivilegefindByTag } from '@/api/systemanager/privilege'
import { getUserById, UserfindNamePage, UserfindPage, getUsers } from '@/api/systemanager/user'
import ELselect from '@/components/myself/el_select'
import { dynamicColumnId } from '@/components/myself/dynamicCol'
import { deleteDialog } from '@/libs/confirmDialog'

export default {
  components: { ELselect },
  data () {
    return {
      queryinfo: {
        queryname: '',
        pageNum: 1,
        pageSize: 8
      },
      total: 0,
      RoleList: [
      ],
      editDialogVisible: false,
      editForm: {
        name: '',
        note: ''
      },
      editFormRules: {
        name: [
          {
            required: true,
            message: '名称不能为空',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 20,
            message: '长度必须在3-20个字符间',
            trigger: 'blur'
          }
        ]
      },
      addDialogVisible: false,
      addForm: {
        name: '',
        note: ''
      },
      addFormRules: {
        name: [
          {
            required: true,
            message: '名称不能为空',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 20,
            message: '长度必须在3-20个字符间',
            trigger: 'blur'
          }
        ]
      },
      // assign privileg
      checked: true,
      multipleAssign: [],
      ALLPrivilegeDialogVisible: false,
      PrivilegeTitle: '',
      PrivilegeAvailList: [],
      privlegeaddedList: [],
      RoleId: '',
      RolePrivilegeIds: [],
      queryAssignInfo: {
        queryname: '',
        pageNum: 1,
        pageSize: 5
      },
      Assigntotal: 0,
      // view privilege
      viewPrivilegeDialogVisible: false,
      PrivilegeviewList: [],
      // 移除 privilege
      removePrivilegeDialogVisible: false,
      multipleRemove: [],
      RemovePrivilegeTitle: '',
      PrivilegemoveList: [],
      confirmRemovedPrivilege: [],

      // role users
      // view role users
      UserviewList: [],
      viewUserDialogVisible: false,
      // add role user
      Names: [],
      Nameskeys: {
        value: 'name',
        label: 'name',
        label2: ''
      },
      checked1: true,
      multipleRoleUser: [],
      ALLRoleUserDialogVisible: false,
      RoleUserTitle: '',
      RoleUserAvailList: [],
      RoleUseraddedList: [],
      RoleUserIds: [],
      queryRoleUserInfo: {
        queryname: '',
        pageNum: 1,
        pageSize: 5
      },
      RoleUsertotal: 0,
      // 移除 role users
      removeRoleUserDialogVisible: false,
      multipleRemoveRoleUser: [],
      RemoveRoleUserTitle: '',
      RoleUsermoveList: [],
      confirmRemovedRoleUser: []
    }
  },
  mounted () {
    this.getRoleList()
    this.getALLName()
  },
  methods: {
    async getRoleList () {
      const res = await RolefindPage(
        this.queryinfo.pageNum,
        this.queryinfo.pageSize
      )
      if (res.status !== 200) {
        return this.$message.error('获取列表失败')
      }
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.RoleList = res.data.data.list
      this.total = res.data.data.total

      if (this.queryinfo.queryname !== '') {
        const res = await RolefindNamePage(
          this.queryinfo.queryname,
          this.queryinfo.pageNum,
          this.queryinfo.pageSize
        )
        if (res.status !== 200) return this.$message.error('获取列表失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.RoleList = res.data.data.list
        this.total = res.data.data.total
      }
    },
    async getALLName () {
      const res = await getUsers()
      if (res.status !== 200) return this.$message.error('获取失败')
      this.Names = res.data.data
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getRoleList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getRoleList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getRoleList()
    },
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    addROle () {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          const resl = await countByName(this.addForm.name)
          if (resl.data.data > 0) return this.$message.error('名称已存在')

          const res = await addRole(this.addForm)
          if (res.status !== 200) return this.$message.error('失败添加角色信息')
          this.$message.success('成功添加角色信息')
          this.addDialogVisible = false
          this.getRoleList()
        }
      })
    },
    async removeRoleById (rowinfo) {
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        const res = await deleteRole(rowinfo.id)
        if (res.status !== 200) return this.$message.error('删除失败')
        this.$message.success('删除成功')
        this.getRoleList()
      }
    },
    async showEditDialog (rowinfo) {
      // 先获取原有数据，再进行修改刷新
      const res = await getRoleById(rowinfo.id)
      if (res.status !== 200) return this.$message.error('修改失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.editForm = res.data.data
      this.editDialogVisible = true
    },
    // 监听修改对话框的关闭事件
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    // 修改信息并提交
    editRoleInfo () {
      this.$refs.editFormRef.validate(async valid => {
        // 可以发现修改信息的请求
        if (valid) {
          const resl = await countByName(this.editForm.name)
          if (resl.data.data > 0) return this.$message.error('名称已存在')

          const res = await updateRole(this.editForm, this.editForm.id)
          if (res.status !== 200) return this.$message.error('失败的更新角色信息')
          this.editDialogVisible = false
          this.getRoleList()
          this.$message.success('成功的更新角色信息')
        }
      })
    },

    // view role privilege
    async viewprivilegeEditDialog (rowinfo) {
      this.PrivilegeviewList = []
      const res = await getRolePermissions(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      // 此处是permissionId，所以需去Permission表中再查找
      res.data.data.forEach(async item => {
        const resl = await getPrivilegeById(item.permissionId)
        if (typeof resl.data.data !== 'undefined') {
          this.PrivilegeviewList.push(resl.data.data)
        }
      })
      this.viewPrivilegeDialogVisible = true
    },

    // assign privilege
    async addprivilegeDialog (rowinfo) {
      this.PrivilegeTitle = '分配角色 ' + rowinfo.name + '权限'
      this.RoleId = rowinfo.id
      this.multipleAssign = []
      this.privlegeaddedList = []
      this.RolePrivilegeIds = []

      const res = await getRolePermissions(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      res.data.data.forEach(item => {
        this.RolePrivilegeIds.push(item.permissionId)
      })
      this.getPrivilegeAvailList()
      this.ALLPrivilegeDialogVisible = true
    },
    // 点击分配权限
    async getPrivilegeAvailList () {
      const res = await PrivilegefindPage(this.queryAssignInfo.pageNum, this.queryAssignInfo.pageSize)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      let flag = 0
      res.data.data.list.forEach(item => {
        if (this.RolePrivilegeIds.toString().includes(item.id)) {
          flag = flag + 1
          this.$set(item, 'PrivilegeExisted', true)
        }
        if (flag === 0) {
          this.$set(item, 'checkchange', false)
        }
      })
      this.PrivilegeAvailList = res.data.data.list
      this.Assigntotal = res.data.data.total

      if (this.queryAssignInfo.queryname !== '') {
        const resl = await PrivilegefindByTag(this.queryAssignInfo.queryname, this.queryAssignInfo.pageNum, this.queryAssignInfo.pageSize)
        if (resl.status !== 200) return this.$message.error('获取列表失败')
        let flag = 0
        resl.data.data.list.forEach(item => {
          if (this.RolePrivilegeIds.toString().includes(item.id)) {
            flag = flag + 1
            this.$set(item, 'PrivilegeExisted', true)
          }
          if (flag === 0) {
            this.$set(item, 'checkchange', false)
          }
        })
        this.PrivilegeAvailList = resl.data.data.list
        this.Assigntotal = resl.data.data.total
      }
    },
    seachChange1 () {
      this.queryAssignInfo.pageNum = 1
      this.getPrivilegeAvailList()
    },
    handleAssignSizeChange (newSize) {
      this.queryAssignInfo.pageSize = newSize
      this.getPrivilegeAvailList()
    },
    handleAssignCurrentChange (newPage) {
      this.queryAssignInfo.pageNum = newPage
      this.getPrivilegeAvailList()
    },
    CheckPrivilege (val) {
      this.multipleAssign = this.multipleAssign.concat(val)
    },
    SelectedPrivlege () {
      this.privlegeaddedList = dynamicColumnId(this.multipleAssign)
      this.privlegeaddedList = this.privlegeaddedList.filter(data => { return data.checkchange === true })
    },
    removePrivlegeById (rowinfo) {
      this.privlegeaddedList = this.privlegeaddedList.filter((data) => { return data.id !== rowinfo.id })
      this.multipleAssign = this.privlegeaddedList
    },
    // 批量添加角色权限
    async AssignPrivilege () {
      if (this.privlegeaddedList.length === 0) return this.$message.error('请选择需要添加的角色权限')

      let addprivlegeids = []
      this.privlegeaddedList.forEach(item => {
        addprivlegeids.push(item.id)
      })
      const res = await batchInsertPermission(addprivlegeids, this.RoleId)
      if (res.status !== 200) return this.$message.error('批量添加操作失败')
      this.ALLPrivilegeDialogVisible = false
      this.privlegeaddedList = []
      this.multipleAssign = []
    },

    // remove privilege
    async removeprivilegeDialog (rowinfo) {
      this.RemovePrivilegeTitle = '移除角色 ' + rowinfo.name + '权限'
      this.RoleId = rowinfo.id
      this.PrivilegemoveList = []
      this.confirmRemovedPrivilege = []
      this.multipleRemove = []

      const res = await getRolePermissions(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      res.data.data.forEach(async item => {
        const resl = await getPrivilegeById(item.permissionId)
        if (typeof resl.data.data !== 'undefined') {
          this.PrivilegemoveList.push(resl.data.data)
        }
      })
      this.removePrivilegeDialogVisible = true
    },
    handleRemovedChange (val) {
      this.multipleRemove = val
    },
    RemovedPrivilege () {

      this.confirmRemovedPrivilege = dynamicColumnId(this.multipleRemove)
    },
    NotremovePrivilegeById (rowinfo) {
      this.confirmRemovedPrivilege = this.confirmRemovedPrivilege.filter((data) => { return data.id !== rowinfo.id })
      this.multipleRemove = this.confirmRemovedPrivilege
    },
    async removePrivilege () {
      if (this.confirmRemovedPrivilege.length === 0) return this.$message.error('请选择需要移除的角色权限')
      const Result = await deleteDialog('确定移除?', this)
      if (Result === 'confirm') {
        let RemovePrivilegeIds = []
        this.confirmRemovedPrivilege.forEach(item => {
          RemovePrivilegeIds.push(item.id)
        })
        const res = await batchDeletePermission(RemovePrivilegeIds, this.RoleId)
        if (res.status !== 200) return this.$message.error('批量移除操作失败')
        this.removePrivilegeDialogVisible = false
        this.confirmRemovedPrivilege = []
        this.multipleRemove = []
      }

    },

    // view role users
    async viewuserEditDialog (rowinfo) {
      this.UserviewList = []
      const res = await getRoleUsers(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      res.data.data.forEach(async item => {
        const resl = await getUserById(item.userId)
        if (typeof resl.data.data !== 'undefined') {
          this.UserviewList.push(resl.data.data)
        }
      })
      this.viewUserDialogVisible = true
    },

    // add role users
    async adduserDialog (rowinfo) {
      this.RoleUserTitle = '分配角色 ' + rowinfo.name + '用户'
      this.RoleId = rowinfo.id
      this.multipleRoleUser = []
      this.RoleUseraddedList = []
      this.RoleUserIds = []

      const res = await getRoleUsers(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      res.data.data.forEach(item => {
        this.RoleUserIds.push(item.userId)
      })
      this.getRoleUserAvailList()
      this.ALLRoleUserDialogVisible = true
    },
    async getRoleUserAvailList () {
      const res = await UserfindPage(this.queryRoleUserInfo.pageNum, this.queryRoleUserInfo.pageSize)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      let flag = 0
      res.data.data.list.forEach(item => {
        if (this.RoleUserIds.toString().includes(item.id)) {
          flag = flag + 1
          this.$set(item, 'RoleUserExisted', true)
        }
        if (flag === 0) {
          this.$set(item, 'checkchange', false)
        }
      })
      this.RoleUserAvailList = res.data.data.list
      this.RoleUsertotal = res.data.data.total

      if (this.queryRoleUserInfo.queryname !== '') {
        const resl = await UserfindNamePage(this.queryRoleUserInfo.queryname, this.queryRoleUserInfo.pageNum, this.queryRoleUserInfo.pageSize)
        if (resl.status !== 200) return this.$message.error('获取列表失败')
        if (resl.data.status !== 200) return this.$message.error(resl.data.message)

        let flag = 0
        resl.data.data.list.forEach(item => {
          if (this.RoleUserIds.toString().includes(item.id)) {
            flag = flag + 1
            this.$set(item, 'RoleUserExisted', true)
          }
          if (flag === 0) {
            this.$set(item, 'checkchange', false)
          }
        })
        this.RoleUserAvailList = resl.data.data.list
        this.RoleUsertotal = resl.data.data.total
      }
    },
    seachChange2 () {
      this.queryRoleUserInfo.pageNum = 1
      this.getRoleUserAvailList()
    },
    handleRoleUserSizeChange (newSize) {
      this.queryRoleUserInfo.pageSize = newSize
      this.getRoleUserAvailList()
    },
    handleRoleUserCurrentChange (newPage) {
      this.queryRoleUserInfo.pageNum = newPage
      this.getRoleUserAvailList()
    },
    CheckRoleUser (val) {
      this.multipleRoleUser = this.multipleRoleUser.concat(val)
    },
    SelectedRoleUser () {
      this.RoleUseraddedList = dynamicColumnId(this.multipleRoleUser)
      this.RoleUseraddedList = this.RoleUseraddedList.filter(data => { return data.checkchange === true })
    },
    removeRoleUserById (rowinfo) {
      this.RoleUseraddedList = this.RoleUseraddedList.filter((data) => { return data.id !== rowinfo.id })
      this.multipleRoleUser = this.RoleUseraddedList
    },
    async RoleUserPrivilege () {
      if (this.RoleUseraddedList.length === 0) return this.$message.error('请选择需要添加的角色用户')

      let addRoleUserids = []
      this.RoleUseraddedList.forEach(item => {
        addRoleUserids.push(item.id)
      })
      const res = await batchInsertUser(this.RoleId, addRoleUserids)
      if (res.status !== 200) return this.$message.error('批量添加操作失败')
      this.ALLRoleUserDialogVisible = false
      this.RoleUseraddedList = []
      this.multipleRoleUser = []
    },

    // remove role user
    async removeuserDialog (rowinfo) {
      this.RemoveRoleUserTitle = '移除角色 ' + rowinfo.name + '用户'
      this.RoleId = rowinfo.id
      this.RoleUsermoveList = []
      this.confirmRemovedRoleUser = []
      this.multipleRemoveRoleUser = []

      const res = await getRoleUsers(rowinfo.id)
      if (res.status !== 200) return this.$message.error('获取列表失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      res.data.data.forEach(async item => {
        const resl = await getUserById(item.userId)
        if (typeof resl.data.data !== 'undefined') {
          this.RoleUsermoveList.push(resl.data.data)
        }
      })
      this.removeRoleUserDialogVisible = true
    },
    handleRemovedRoleUserChange (val) {
      this.multipleRemoveRoleUser = val
    },
    RemovedRoleUser () {
      this.confirmRemovedRoleUser = dynamicColumnId(this.multipleRemoveRoleUser)
    },
    NotremoveRoleUserById (rowinfo) {
      this.confirmRemovedRoleUser = this.confirmRemovedRoleUser.filter((data) => { return data.id !== rowinfo.id })
      this.multipleRemoveRoleUser = this.confirmRemovedRoleUser
    },
    async removeRoleUser () {
      if (this.confirmRemovedRoleUser.length === 0) return this.$message.error('请选择需要移除的角色用户')
      const Result = await deleteDialog('确定移除?', this)
      if (Result === 'confirm') {
        let RemoveRoleUserIds = []
        this.confirmRemovedRoleUser.forEach(item => {
          RemoveRoleUserIds.push(item.id)
        })
        const res = await batchDeleteUser(this.RoleId, RemoveRoleUserIds)
        if (res.status !== 200) return this.$message.error('批量移除操作失败')
        this.removeRoleUserDialogVisible = false
        this.confirmRemovedRoleUser = []
        this.multipleRemoveRoleUser = []

      }
    }
  }
}
</script>
<style lang="less" scoped>
.texts {
  color: blue;
  font-size: 16px;
}
.divSpan {
  font-size: 16px;
}
</style>